









































































































.main_container_for_correction {
  .like_table {
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    .is_name {
      flex-grow: 1;
      padding: 0 20px;
    }
    .wrap_test {
      min-width: 400px;
      display: flex;
      .is_test_name {
        width: 200px;
        display: flex;
        justify-content: center;
        &.for_pre {
          // background: #eee;
        }
      }
    }
  }
  .every_card {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #777;
      text-transform: capitalize;
      flex-grow: 1;
    }
  }
  .correct_wrapper_buttons {
    min-width: 400px;
    display: flex;

    .parts {
      width: 200px;
      display: flex;
      justify-content: center;

      .correct_button {
        background-color: #87cb16;
        color: #fff;
        border: 1px solid #87cb16;
        min-width: 100px;
        &.marked {
          opacity: 0.5;
        }
      }
    }
  }
}
